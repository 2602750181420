import {
  makeObservable,
  action,
  observable,
  flow,
  flowResult,
  when,
  reaction
} from 'mobx'
import algoliasearch, {SearchClient} from 'algoliasearch'
import type {CommerceAPI} from 'commerce-api'
import type {RootStore} from './RootStore'
import type {StoreModel} from './utils'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'
import {Nullable} from '../types/utils'
import {NavItem} from './ContentStore'
import type {CustomSitePreferences} from '../types/store/common'
import { StoreDetailResponseItem } from '../types/store/storeDetails'

export class GlobalStore implements StoreModel {
  api: CommerceAPI
  rootStore: RootStore
  locale: string
  currency: string
  appHostname: string
  appOrigin: string
  deployTarget: string
  customerCacheClearInterval: number
  organizationId: string
  clientId: string
  siteId: string
  algoliaIndexBase: string
  algoliaAppId: string
  algoliaApiKey: string
  algoliaAnalyticsAPIKey: string
  einsteinConfiguration: {
    siteId: string
    einsteinId: string
  }
  selectedRegion: number
  selectedStoreId?: Nullable<string>
  selectedPriceDayOffset?: number
  selectedAvailabilityDayOffset?: Nullable<number>
  loqateAPIKey: string
  recaptchaSiteKey: string
  googleMapsAPIkey: string
  customSitePreferences: CustomSitePreferences
  customSitePreferencesLoading: Set<string>
  customObjectDateOffsetAvailability: number
  customObjectDateOffsetPricing: number
  affiliateTrackingCampaign: string
  affiliateTrackingSource: string
  affiliateTrackingMedium: string
  dataLoadedForAccuratePricingAndAvailabilty: boolean
  showCheckoutSummary: boolean
  gtmId: string
  sentryInitialized: boolean
  sentryEnabled: boolean
  sentryEnv: string
  sentryDSN: string
  algoliaRefinements: Array<string>
  paypalClient: string
  searchClient: SearchClient
  appVersion: string
  alertsUrl: string

  constructor(
    rootStore: RootStore,
    initialState: {
      locale: string
      currency: string
      appHostname: string
      appOrigin: string
      deployTarget: string
      customerCacheClearInterval: number
      organizationId: string
      clientId: string
      siteId: string
      algoliaIndexBase: string
      algoliaAppId: string
      algoliaApiKey: string
      algoliaAnalyticsAPIKey: string
      loqateAPIKey: string
      recaptchaSiteKey: string
      googleMapsAPIkey: string
      einsteinConfiguration: {
        siteId: string
        einsteinId: string
      }
      showCheckoutSummary: boolean
      customObjectDateOffsetAvailability: number
      customObjectDateOffsetPricing: number
      dataLoadedForAccuratePricingAndAvailabilty: boolean
      gtmId: string
      sentryInitialized: boolean
      sentryEnabled: boolean
      sentryEnv: string
      sentryDSN: string
      algoliaRefinements: Array<string>
      paypalClient: string
      customSitePreferences: CustomSitePreferences
      appVersion: string
      alertsUrl: string
      affiliateTrackingCampaign: string
      affiliateTrackingSource: string
      affiliateTrackingMedium: string
    },
  ) {
    this.locale = initialState.locale
    this.currency = initialState.currency
    this.appHostname = initialState.appHostname
    this.appOrigin = initialState.appOrigin
    this.deployTarget = initialState.deployTarget
    this.customerCacheClearInterval = initialState.customerCacheClearInterval
    this.organizationId = initialState.organizationId
    this.clientId = initialState.clientId
    this.siteId = initialState.siteId
    this.algoliaIndexBase = initialState.algoliaIndexBase
    this.einsteinConfiguration = initialState.einsteinConfiguration
    this.loqateAPIKey = initialState.loqateAPIKey
    this.recaptchaSiteKey = initialState.recaptchaSiteKey
    this.googleMapsAPIkey = initialState.googleMapsAPIkey
    this.algoliaAnalyticsAPIKey = initialState.algoliaAnalyticsAPIKey
    this.algoliaAppId = initialState.algoliaAppId
    this.algoliaApiKey = initialState.algoliaApiKey
    this.selectedStoreId = null
    this.selectedRegion = 0
    this.selectedPriceDayOffset = 1
    this.selectedAvailabilityDayOffset = null
    this.customSitePreferences = {
      ...(initialState.customSitePreferences || {}),
      // Remove those BT custom properties.
      BRAINTREE_PAYPAL_Billing_Agreement_Description:
        'Your money will only leave your account on the day of your delivery.',
    }
    this.customSitePreferencesLoading = new Set<string>()
    this.showCheckoutSummary = false
    this.customObjectDateOffsetAvailability = 0
    this.customObjectDateOffsetPricing = 0
    this.dataLoadedForAccuratePricingAndAvailabilty = false
    this.gtmId = initialState.gtmId
    this.sentryInitialized = initialState.sentryInitialized
    this.sentryEnabled = initialState.sentryEnabled
    this.sentryEnv = initialState.sentryEnv
    this.sentryDSN = initialState.sentryDSN
    this.algoliaRefinements = []
    this.paypalClient = initialState.paypalClient
    this.appVersion = initialState.appVersion
    this.alertsUrl = initialState.alertsUrl
    this.affiliateTrackingCampaign = initialState.affiliateTrackingCampaign
    this.affiliateTrackingSource = initialState.affiliateTrackingSource
    this.affiliateTrackingMedium = initialState.affiliateTrackingMedium

    makeObservable(this, {
      locale: observable,
      currency: observable,
      appHostname: observable,
      appOrigin: observable,
      deployTarget: observable,
      customerCacheClearInterval: observable,
      organizationId: observable,
      clientId: observable,
      siteId: observable,
      selectedStoreId: observable,
      selectedPriceDayOffset: observable,
      selectedAvailabilityDayOffset: observable,
      algoliaIndexBase: observable,
      algoliaAnalyticsAPIKey: observable,
      algoliaAppId: observable,
      loqateAPIKey: observable,
      recaptchaSiteKey: observable,
      googleMapsAPIkey: observable,
      einsteinConfiguration: observable,
      customSitePreferences: observable,
      affiliateTrackingCampaign: observable,
      affiliateTrackingMedium: observable,
      affiliateTrackingSource: observable,
      showCheckoutSummary: observable,
      setLocale: action,
      setCurrency: action,
      setSelectedStoreId: action.bound,
      setShowCheckoutSummary: action.bound,
      setSelectedRegion: flow.bound,
      setAffiliateTrackingData: action.bound,
      setAffiliateTrackingDataForSession: action.bound,
      updateMenuItemCategory: action.bound,
      setSelectedPriceDay: action.bound,
      setSelectedAvailabilityDay: action.bound,
      setDataLoadedForAccuratePricingAndAvailabilty: action,
      isCustomSitePreferenceSet: action.bound,
      getCustomSitePreferencesByIds: flow.bound,
      getCustomSitePreferenceById: flow.bound,
      getCustomObjectDateOffsets: flow.bound,
      gtmId: observable,
      sentryInitialized: observable,
      sentryEnabled: observable,
      sentryEnv: observable,
      sentryDSN: observable,
      algoliaRefinements: observable,
      getAlgoliaRefinements: flow.bound,
      setAndGetCustomPreferenceValueById: flow.bound,
      paypalClient: observable,
      appVersion: observable,
      alertsUrl: observable,
    })

    this.api = rootStore.api
    this.rootStore = rootStore

    this.searchClient = algoliasearch(this.algoliaAppId, this.algoliaApiKey)
    reaction(
      () => this.selectedStoreId,
      () => {
        this.dataLoadedForAccuratePricingAndAvailabilty = true;
      }
    )
  }

  get asJson() {
    return {
      locale: this.locale,
      currency: this.currency,
      appHostname: this.appHostname,
      appOrigin: this.appOrigin,
      deployTarget: this.deployTarget,
      customerCacheClearInterval: this.customerCacheClearInterval,
      clientId: this.clientId,
      organizationId: this.organizationId,
      siteId: this.siteId,
      algoliaIndexBase: this.algoliaIndexBase,
      einsteinConfiguration: this.einsteinConfiguration,
      loqateAPIKey: this.loqateAPIKey,
      algoliaAppId: this.algoliaAppId,
      algoliaApiKey: this.algoliaApiKey,
      algoliaAnalyticsAPIKey: this.algoliaAnalyticsAPIKey,
      recaptchaSiteKey: this.recaptchaSiteKey,
      googleMapsAPIkey: this.googleMapsAPIkey,
      selectedPriceDayOffset: this.selectedPriceDayOffset,
      selectedAvailabilityDayOffset: this.selectedAvailabilityDayOffset,
      dataLoadedForAccuratePricingAndAvailabilty: this.dataLoadedForAccuratePricingAndAvailabilty,
      gtmId: this.gtmId,
      sentryInitialized: this.sentryInitialized,
      sentryEnabled: this.sentryEnabled,
      sentryEnv: this.sentryEnv,
      sentryDSN: this.sentryDSN,
      customSitePreferences: this.customSitePreferences,
      paypalClient: this.paypalClient,
      appVersion: this.appVersion,
      alertsUrl: this.alertsUrl,
      affiliateTrackingCampaign: this.affiliateTrackingCampaign,
      affiliateTrackingMedium: this.affiliateTrackingMedium,
      affiliateTrackingSource: this.affiliateTrackingSource
    }
  }

  setSelectedStoreId() {
    const storeId = this.rootStore.basketStore.basket?.c_deliveryStoreId
    const appliedPostCodeDeliverable = this.rootStore.basketStore.isAppliedPostCodeDeliverable
    this.setSelectedRegion()
    this.selectedStoreId = appliedPostCodeDeliverable ? storeId ?? '0' : '0'
  }

  *setSelectedRegion(isPostcodeAvailable?: boolean) {
    if (
      (typeof isPostcodeAvailable !== 'undefined' && !isPostcodeAvailable) ||
      this.rootStore.basketStore.isAppliedPostCodeDeliverable == null
    ) {
      this.selectedRegion = 0

      return
    }

    let store: Nullable<StoreDetailResponseItem> = null

    if (this.rootStore.basketStore.basket?.c_deliveryStoreId == null) {
      return
    }

    if (this.rootStore.basketStore.basket?.c_deliveryStoreId !== this.selectedStoreId) {
      if (this.rootStore.basketStore.currentDeliveryStoreId) {
        store = yield flowResult(
          this.rootStore.storeDetailsStore.findStore(
            this.rootStore.basketStore.basket?.c_deliveryStoreId,
          ),
        )
      }

      this.selectedRegion =
        store?.c_priceBookRegion != null ? parseInt(store?.c_priceBookRegion, 10) : 0
    }
  }

  get badgesCustomPreferences() {
    return {
      topRightBadgeCustomPreference: this.customSitePreferences.isPdpTopRightTileBadgeEnabled,
      middleRightBadgeCustomPreference: this.customSitePreferences.isPdpMiddleRightTileBadgeEnabled,
      bottomRightBadgeCustomPreference: this.customSitePreferences.isPdpBottomRightTileBadgeEnabled,
    }
  }

  get storeAndDaySpecificNavigation() {
    const baseNavMenu = this.rootStore.contentStore.navMenu
    const routeMap = this.rootStore.contentStore.routeMap

    const filterMenu = (menuItems: Array<NavItem>) => {
      const filteredMenu = []

      for (const menuItem of menuItems) {
        let shouldPush = false

        if (menuItem.path) {
          const routeData = routeMap[menuItem.path]
          const category = routeData?.split('|')[2]
          this.updateMenuItemCategory(menuItem, category)
          shouldPush = true
        }

        if (!menuItem.path && menuItem.children?.length) {
          shouldPush = true
        }

        let filteredChildren: Array<NavItem> = []
        if (menuItem.children && menuItem.children.length > 0) {
          filteredChildren = filterMenu(menuItem.children)
        }

        if (shouldPush || filteredChildren.length > 0) {
          const clonedMenuItem = {...menuItem}
          if (filteredChildren.length > 0) {
            clonedMenuItem.children = filteredChildren
          }
          filteredMenu.push(clonedMenuItem)
        }
      }

      return filteredMenu
    }

    const filteredMenu = filterMenu(baseNavMenu)
    return filteredMenu
  }

  updateMenuItemCategory = (menuItem: NavItem, categoryId: string) => {
    menuItem.categoryId = categoryId
  }

  getDiffInDays(offsetDays: number) {
    // When algolia is syncing everyday we need to use the customObjectDateOffsetAvailability and customObjectDateOffsetPricing
    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)
    const slotDate = this.rootStore.basketStore.basket?.c_windowStartTime

    // Set the date as tomorrow if there is no slot booked or an old slot is booked
    const deliveryDate = slotDate && new Date(slotDate) > today ? new Date(slotDate) : tomorrow
    const oneDay = 86400000 // 24 * 60 * 60 * 1000 // hours * minutes * seconds * milliseconds
    const diffInDays = offsetDays + Math.round(Math.abs((Number(deliveryDate) - Number(today)) / oneDay))

    return diffInDays
  }

  setSelectedPriceDay() {
    const newPriceDayOffset = this.getDiffInDays(this.customObjectDateOffsetPricing)
    // Only update if it has changed
    if (newPriceDayOffset != this.selectedPriceDayOffset) {
      this.selectedPriceDayOffset = newPriceDayOffset
    }
  }

  setSelectedAvailabilityDay() {
    const newAvailableDayOffset = this.getDiffInDays(this.customObjectDateOffsetAvailability)
    // Only update if it has changed
    if (newAvailableDayOffset != this.selectedAvailabilityDayOffset) {
      this.selectedAvailabilityDayOffset = newAvailableDayOffset
    }
  }

  setAffiliateTrackingData(headers: any) {
    try {
      if (headers['utm-campaign']) {
        this.affiliateTrackingCampaign = headers['utm-campaign']
      }
      if (headers['utm-medium']) {
        this.affiliateTrackingMedium = headers['utm-medium']
      }
      if (headers['utm-source']) {
        this.affiliateTrackingSource = headers['utm-source']
      }
    } catch (error) {
      console.error(error)
    }
  }

  setAffiliateTrackingDataForSession() {
    try {
      if (this.affiliateTrackingCampaign) {
        sessionStorage.setItem('affiliateTrackingCampaign', this.affiliateTrackingCampaign)
        this.affiliateTrackingCampaign = ''
      }
      if (this.affiliateTrackingMedium) {
        sessionStorage.setItem('affiliateTrackingMedium', this.affiliateTrackingMedium)
        this.affiliateTrackingMedium = ''
      }
      if (this.affiliateTrackingSource) {
        sessionStorage.setItem('affiliateTrackingSource', this.affiliateTrackingSource)
        this.affiliateTrackingSource = ''
      }
    } catch (error) {
      console.error(error)
    }
  }

  setDataLoadedForAccuratePricingAndAvailabilty() {
    this.dataLoadedForAccuratePricingAndAvailabilty = true
    this.getAlgoliaRefinements()
  }

  setLocale(locale: string) {
    if (this.locale != locale) {
      this.locale = locale
    }
  }

  setCurrency(currency: string) {
    if (this.currency != currency) {
      this.currency = currency
    }
  }

  setShowCheckoutSummary(show: boolean) {
    this.showCheckoutSummary = show
  }

  isCustomSitePreferenceSet(preferenceId: string) {
    return preferenceId in this.customSitePreferences
  }

  *getCustomSitePreferenceById(preferenceId: string) {
    try {
      if (!preferenceId) {
        return
      }

      if (this.customSitePreferencesLoading.has(preferenceId)) {
        yield when(() => !this.customSitePreferencesLoading.has(preferenceId))
      } else {
        this.customSitePreferencesLoading.add(preferenceId)

        const response: Response = yield fetch(
          `${getAppOrigin()}/api/getCustomSitePreferenceById?preferenceId=${preferenceId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        if (!response.ok) {
          const {errMessage}: {errMessage: string} = yield response.json()

          throw new Error(
            'Error during fetching custom site preference: ' + preferenceId + ` ${errMessage}`,
          )
        }

        const result: Record<string, unknown> = yield response.json()

        this.customSitePreferences = {...this.customSitePreferences, ...(result || {})}
        this.customSitePreferencesLoading.delete(preferenceId)
      }
    } catch (error) {
      console.error(error)
    }
  }

  *getCustomSitePreferencesByIds(preferenceIds: string[]) {
    try {
      if (!preferenceIds.length) {
        return
      }

      const response: Response = yield fetch(`${getAppOrigin()}/api/getSitePreferences`, {
        method: 'POST',
        body: JSON.stringify({
          preferenceIds: preferenceIds,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (!response.ok) {
        const {errMessage}: {errMessage: string} = yield response.json()
        throw new Error(
          'Error during fetching custom site preferences: ' +
            preferenceIds.toString() +
            ' ' +
            errMessage,
        )
      }

      const result: Record<string, unknown> = yield response.json()
      this.customSitePreferences = {...this.customSitePreferences, ...(result || {})}
    } catch (error) {
      console.error(error)
    }
  }

  *getCustomObjectDateOffsets() {
    try {
      const response: {c_priceOffsetDays: number, c_availabilityOffsetDays: number} = yield this.api.shopperCustomObjects.getOffsetDatePricing({})
      // Handle Price offset
      if (Object.prototype.hasOwnProperty.call(response,'c_priceOffsetDays')) {
        this.customObjectDateOffsetPricing = response.c_priceOffsetDays
      }
      this.setSelectedPriceDay()

      // Handle Availability offset
      if (Object.prototype.hasOwnProperty.call(response,'c_availabilityOffsetDays')) {
        this.customObjectDateOffsetAvailability = response.c_availabilityOffsetDays
      }
      this.setSelectedAvailabilityDay()

      // Trigger Flag that we're ready for Search calls requireing availability and pricing data
      this.rootStore.globalStore.setDataLoadedForAccuratePricingAndAvailabilty()
    } catch (error) {
      console.error(error)
    }
  }

  *getAlgoliaRefinements() {
    const reqOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({day: this.selectedAvailabilityDayOffset, store: this.selectedStoreId}),
    }

    try {
      const response: Response = yield fetch(`${getAppOrigin()}/algolia/refinements`, reqOptions)

      if (response.ok) {
        const responseJson: Array<string> = yield response.json()

        this.algoliaRefinements = responseJson
      } else {
        console.error('Error response from API:', response.statusText)
      }
    } catch (error) {
      console.error(error)
    }
  }

  async *setAndGetCustomPreferenceValueById(preferenceId: string) {
    if (!this.isCustomSitePreferenceSet(preferenceId)) {
      await this.getCustomSitePreferenceById(preferenceId)
    }
    return this.customSitePreferences[preferenceId]
  }
}
